import React from 'react'
import cx from 'clsx'

import Button from '@components/Button'

import { SortOrder } from '@type/common'
import { IconSelector, IconSortAscending, IconSortDescending } from '@tabler/icons-react'

import i18n from '@src/i18n'
import styles from './index.module.css'

export interface SortableHeaderProps {
  order?: SortOrder
  onClick?: React.MouseEventHandler<Element>
  className?: string
}

const SortIconElement: React.FC<{
  order?: SortOrder
}> = ({ order }) => {
  let tooltipMessage = i18n.t('pipelines:list.ascSortTip')
  if (order === SortOrder.asc) {
    tooltipMessage = i18n.t('pipelines:list.descSortTip')
  } else if (order === SortOrder.desc) {
    tooltipMessage = i18n.t('pipelines:list.disableSortTip')
  }

  return (
    <div className="p-0 m-0" data-tooltip-id="sort_tip" data-tooltip-content={tooltipMessage}>
      {order !== SortOrder.asc && order !== SortOrder.desc && (
        <IconSelector className={cx(styles.actionsButton, 'text-slate-950')} />
      )}
      {order === SortOrder.asc && <IconSortAscending className={styles.actionsButton} />}
      {order === SortOrder.desc && <IconSortDescending className={styles.actionsButton} />}
    </div>
  )
}

export const SortableHeader: React.FC<React.PropsWithChildren<SortableHeaderProps>> = ({
  children,
  order,
  onClick,
  className,
}) => {
  return (
    <Button className={cx('flex items-center gap-1 !p-0 !text-black', className)} onClick={onClick} type="contained">
      {children}
      <SortIconElement order={order} />
    </Button>
  )
}

export default SortableHeader
